import { AsyncData, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { AutoWidthImage } from "@swan-io/lake/src/components/AutoWidthImage";
import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FlatList } from "@swan-io/lake/src/components/FlatList";
import { Icon } from "@swan-io/lake/src/components/Icon";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { SwanLogo } from "@swan-io/lake/src/components/SwanLogo";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { backgroundColor, breakpoints, colors, spacings } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { showToast } from "@swan-io/lake/src/state/toasts";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import { Request, badStatusToError } from "@swan-io/request";
import { translateError } from "@swan-io/shared-business/src/utils/i18n";
import { StyleSheet, View } from "react-native";
import { P, match } from "ts-pattern";
import { ErrorView } from "../components/ErrorView";
import { Redirect } from "../components/Redirect";
import { ProjectListPageDocument } from "../graphql/exposed-internal";
import { env } from "../utils/env";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  base: {
    ...commonStyles.fill,
    backgroundColor: backgroundColor.default,
    paddingHorizontal: spacings[24],
  },
  logo: {
    height: 20,
    marginTop: spacings[24],
    width: "100%",
    paddingVertical: 5,
  },
  content: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: spacings[24],
  },
  tile: {
    width: "100%",
    maxHeight: "100%",
    maxWidth: 400,
    paddingHorizontal: 0,
    paddingVertical: 0,
  },
  tileWithoutAccounts: {
    maxWidth: 600,
  },
  noAccountContainer: {
    padding: spacings[24],
  },
  noAccountContainerDesktop: {
    padding: spacings[72],
  },
  listTitle: {
    padding: spacings[24],
  },
  list: {
    width: "100%",
  },
  link: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: spacings[24],
    paddingRight: spacings[20],
  },
});

const logout = () => {
  Request.make({ url: `${env.BANKING_URL}/auth/logout`, method: "POST", withCredentials: true })
    .mapOkToResult(badStatusToError)
    .tapOk(() => {
      window.location.replace(Router.Login());
    })
    .tapError(error => {
      showToast({ variant: "error", error, title: translateError(error) });
    });
};

export const ProjectListPage = () => {
  const { desktop } = useResponsive(breakpoints.medium);
  const [data] = useQuery(ProjectListPageDocument, {});

  return match(data)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
    .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
    .with(AsyncData.P.Done(Result.P.Ok(P.select())), ({ projectInfos }) => (
      <View style={styles.base}>
        <Box role="banner" alignItems="center">
          <SwanLogo style={styles.logo} />
        </Box>

        <View style={styles.content}>
          {match(projectInfos)
            .with([], () => {
              const content = (
                <Box
                  alignItems="center"
                  style={[styles.noAccountContainer, desktop && styles.noAccountContainerDesktop]}
                >
                  <BorderedIcon name="lake-building-bank" size={100} padding={8} />
                  <Space height={24} />

                  <LakeText variant="medium" color={colors.gray[900]} align="center">
                    {t("home.noAccount.title")}
                  </LakeText>

                  <Space height={4} />
                  <LakeText align="center">{t("home.noAccount.description")}</LakeText>
                  <Space height={32} />

                  <LakeButton mode="secondary" icon="sign-out-regular" onPress={logout}>
                    {t("home.noAccount.logout")}
                  </LakeButton>
                </Box>
              );

              return match(desktop)
                .with(false, () => content)
                .otherwise(() => (
                  <Tile style={[styles.tile, styles.tileWithoutAccounts]}>{content}</Tile>
                ));
            })
            .with([P.select()], project => (
              <Redirect to={`${env.BANKING_URL}/projects/${project.id}`} />
            ))
            .otherwise(projects => (
              <Tile style={styles.tile}>
                <LakeHeading
                  level={1}
                  variant="h5"
                  align="center"
                  color={colors.gray[900]}
                  style={styles.listTitle}
                >
                  {t("home.selectPartner")}
                </LakeHeading>

                <FlatList
                  style={styles.list}
                  keyExtractor={item => item.id}
                  data={projects}
                  // on mobile we don't use ItemSeparatorComponent because we want a separator at the end of the list
                  ItemSeparatorComponent={<Separator />}
                  renderItem={({ item }) => (
                    <Link to={`${env.BANKING_URL}/projects/${item.id}`} style={styles.link}>
                      {isNotNullish(item.logoUri) && (
                        <AutoWidthImage height={22} sourceUri={item.logoUri} />
                      )}

                      <Fill minWidth={16} />

                      <LakeText numberOfLines={1} color={colors.gray[500]}>
                        {item.name}
                      </LakeText>

                      <Space width={8} />
                      <Icon name="chevron-right-filled" color={colors.gray[500]} size={16} />
                    </Link>
                  )}
                />
              </Tile>
            ))}
        </View>
      </View>
    ))
    .exhaustive();
};
