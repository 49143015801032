import { AsyncData } from "@swan-io/boxed";
import { ClientContext, useQuery } from "@swan-io/graphql-client";
import { ErrorBoundary } from "@swan-io/lake/src/components/ErrorBoundary";
import { ToastStack } from "@swan-io/lake/src/components/ToastStack";
import { colors } from "@swan-io/lake/src/constants/design";
import { isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { Redirect } from "../../dashboard/src/components/Redirect";
import { ErrorView } from "./components/ErrorView";
import { AuthStatusDocument } from "./graphql/exposed-internal";
import { IdentificationCallbackPage } from "./pages/IdentificationCallbackPage";
import { LoginPage } from "./pages/LoginPage";
import { NotFoundPage } from "./pages/NotFoundPage";
import { PopupCallbackPage } from "./pages/PopupCallbackPage";
import { ProjectListPage } from "./pages/ProjectListPage";
import { client } from "./utils/gql";
import { logFrontendError } from "./utils/logger";
import { Router } from "./utils/routes";

const styles = StyleSheet.create({
  base: {
    backgroundColor: colors.gray[50],
    flexGrow: 1,
  },
});

const AppContainer = () => {
  const route = Router.useRoute(["Login", "ProjectList"]);
  const [authStatus] = useQuery(AuthStatusDocument, {});

  const loginInfo = authStatus
    .mapOk(data => data.user?.id != null)
    .map(result => ({ isLoggedIn: result.getOr(false) }));

  return match(loginInfo)
    .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => null)
    .with(AsyncData.P.Done(P.select()), ({ isLoggedIn }) => {
      return match(route)
        .with({ name: "Login" }, ({ params: { sessionExpired } }) =>
          isLoggedIn ? (
            // Skip login and redirect to the root URL
            <Redirect to={Router.ProjectList()} />
          ) : (
            <LoginPage sessionExpired={isNotNullishOrEmpty(sessionExpired)} />
          ),
        )
        .with({ name: "ProjectList" }, () =>
          isLoggedIn ? <ProjectListPage /> : <Redirect to={Router.Login()} />,
        )
        .with(P.nullish, () => <NotFoundPage style={styles.base} />)
        .exhaustive();
    })
    .exhaustive();
};

export const App = () => {
  const route = Router.useRoute(["PopupCallback", "IdentificationCallback"]);

  return (
    <ErrorBoundary
      key={route?.name}
      onError={error => logFrontendError(error)}
      fallback={() => <ErrorView style={styles.base} />}
    >
      {match(route)
        .with({ name: "IdentificationCallback" }, () => <IdentificationCallbackPage />)
        .with({ name: "PopupCallback" }, () => <PopupCallbackPage />)
        .otherwise(() => (
          <ClientContext.Provider value={client}>
            <AppContainer />
            <ToastStack />
          </ClientContext.Provider>
        ))}
    </ErrorBoundary>
  );
};
