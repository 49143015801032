import { BorderedIcon } from "@swan-io/lake/src/components/BorderedIcon";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { Link } from "@swan-io/lake/src/components/Link";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Separator } from "@swan-io/lake/src/components/Separator";
import { Space } from "@swan-io/lake/src/components/Space";
import { SwanLogo } from "@swan-io/lake/src/components/SwanLogo";
import { Tile } from "@swan-io/lake/src/components/Tile";
import { backgroundColor, breakpoints, colors, spacings } from "@swan-io/lake/src/constants/design";
import { useResponsive } from "@swan-io/lake/src/hooks/useResponsive";
import { isDecentMobileDevice } from "@swan-io/lake/src/utils/userAgent";
import { useCallback, useEffect, useRef } from "react";
import { StyleSheet, View } from "react-native";
import { openPopup } from "../states/popup";
import { env } from "../utils/env";
import { getFirstSupportedLanguage, t } from "../utils/i18n";
import { Router } from "../utils/routes";

const styles = StyleSheet.create({
  base: {
    backgroundColor: backgroundColor.default,
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    marginHorizontal: "auto",
    justifyContent: "center",
    padding: spacings[24],
  },
  logo: {
    height: 20,
    paddingVertical: 5,
    width: "100%",
  },
  tile: {
    paddingHorizontal: spacings[72],
    paddingVertical: spacings[72],
  },
  iconContainer: {
    margin: "auto",
  },
  link: {
    wordBreak: "keep-all",
  },
  underline: {
    textDecorationLine: "underline",
  },
});

const HelpLink = ({ to, children }: { to: string; children: string }) => (
  <LakeText variant="smallRegular" style={styles.link}>
    <Link to={to} style={({ hovered }) => hovered && styles.underline} target="blank">
      {children}
    </Link>
  </LakeText>
);

const SUPPORT_ROOT_URL = `https://support.swan.io/hc/${getFirstSupportedLanguage([
  "en",
  "fr",
  "de",
  "es",
  "it",
])}`;

type LoginContentProps = {
  onLogin: () => void;
};

const LoginContent = ({ onLogin }: LoginContentProps) => {
  return (
    <>
      <View style={styles.iconContainer}>
        <BorderedIcon name="lake-building-bank" size={100} padding={8} color="partner" />
      </View>

      <Space height={32} />

      <LakeButton color="partner" onPress={onLogin}>
        {t("login.buttonText")}
      </LakeButton>

      <Separator space={32} />

      <LakeText color={colors.gray[900]} variant="semibold">
        {t("login.needHelp")}
      </LakeText>

      <Space height={8} />
      <HelpLink to={`${SUPPORT_ROOT_URL}-150`}>{t("login.linkHow")}</HelpLink>
      <Space height={8} />

      <HelpLink to={`${SUPPORT_ROOT_URL}-150/articles/5490446960797-How-do-I-report-fraud-`}>
        {t("login.linkFraud")}
      </HelpLink>
    </>
  );
};

type Props = {
  sessionExpired?: boolean;
};

export const LoginPage = ({ sessionExpired = false }: Props) => {
  const { desktop } = useResponsive(breakpoints.medium);

  // we store initial sessionExpired value, then remove it from the url
  const sessionExpiredWarningVisible = useRef(sessionExpired).current;

  useEffect(() => {
    Router.replace("Login");
  }, []);

  const handleButtonPress = useCallback(() => {
    const redirectUrl = Router.ProjectList();

    if (isDecentMobileDevice) {
      window.location.replace(`${env.BANKING_URL}/auth/login`);
    } else {
      openPopup(`${env.BANKING_URL}/auth/login`).onResolve(() => {
        // We use location.replace to be sure that the auth
        // cookie is correctly written before changing page
        // (history pushState does not seem to offer these guarantees)
        window.location.replace(redirectUrl);
      });
    }
  }, []);

  return (
    <ScrollView style={styles.base} contentContainerStyle={styles.content}>
      <Box role="banner" alignItems="center">
        <SwanLogo style={styles.logo} />
      </Box>

      <Fill minHeight={48} />

      {sessionExpiredWarningVisible && (
        <>
          <LakeAlert variant="warning" title={t("login.sessionExpired.title")} />
          <Space height={desktop ? 24 : 48} />
        </>
      )}

      {desktop ? (
        <Tile style={styles.tile}>
          <LoginContent onLogin={handleButtonPress} />
        </Tile>
      ) : (
        <View>
          <LoginContent onLogin={handleButtonPress} />
        </View>
      )}

      <Fill minHeight={48} />
    </ScrollView>
  );
};
